<template>
  <div class="background-bg" style="">
    <!--  底层背景图-->
    <!--<div class="background border-red">-->
    <!--  <img :src="imgSrc" width="100%" height="100%">-->
    <!--</div>-->
    <!--  logo图-->
    <div style="position: absolute">
      <v-img src="@/assets/png/logotitle.png" style="width: 450px;height: 70px;z-index: 5;margin-left: 10px; margin-top: 10px;"></v-img>
    </div>

    <div class="" style="width:100%; position: absolute; margin-top: 180px; display: flex; justify-content: center">
      <div class="" style="width: 70%; display: flex; flex-direction: column; justify-content: center">
        <div style="color: #0390f2;font-size: 72px;font-weight: bold; text-align: center; margin: auto">
          助推滑翔导弹模块化设计与飞行验证
        </div>
        <div class="" style="color: #0390f2;font-size: 30px;font-weight: bold;
              white-space: nowrap; justify-self: center; align-self: flex-end;">
          虚拟仿真实验
        </div>
      </div>
    </div>

    <div class="" style="position: absolute; left: 0; right: 0; top:350px; margin: auto;
          width:500px; height: 400px; background-color: rgba(43,148,241,0.3); border-radius: 10px;
          box-shadow: #0088ff 0px 0px 10px inset;
          display: flex; flex-direction: column; align-items: center; justify-content: center;">
      <v-col class="" style="display: flex; justify-content: center; align-items: center; width: 80%;">
        <v-text-field
            v-model="name"
            dark
            solo-inverted
            placeholder="用户名"
            hide-details
            style="font-size: 20px;"
        >
          <template #prepend>
            <v-icon large>mdi-account</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col class="d-flex align-center" style="width: 80%;">
        <v-text-field
            v-model="pwd"
            dark
            solo-inverted
            placeholder="密码"
            style="font-size: 20px"
            :type="showPwd ? 'text' : 'password'"
            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPwd = !showPwd"
            validate-on-blur
            @keyup.enter.native="login"
        >
          <template #prepend>
            <v-icon large>mdi-lock</v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col class="d-flex justify-space-around" style="display: flex; justify-content: center;">
        <v-btn style="background-color: #0390f2; font-size: 24px; width: 150px;height: 50px;" width="120" height="50" color="success" @click.native.prevent="login">登录</v-btn>
      </v-col>
    </div>

    <!--  登录页面-->
    <!--<div class="bg" style="margin-top: 480px">-->
    <!--  <div class="input">-->
    <!--    &lt;!&ndash;    <input type="text" v-model="name"/>-->
    <!--        <span v-if="error.name" class="err-msg" style="background-color: white">{{ error.name }}</span>&ndash;&gt;-->
    <!--    <v-img src="@/assets/png/loginbj.png" style="margin-top: -30%"></v-img>-->
    <!--    <div class="dl">-->
    <!--      <v-row>-->
    <!--        <v-col>-->
    <!--          <span style="color: white;margin-left: 52%">登录名</span>-->
    <!--        </v-col>-->
    <!--        <v-col>-->
    <!--          <v-text-field-->
    <!--              type="text"-->
    <!--              v-model="name"-->
    <!--              dark-->
    <!--              outlined-->
    <!--          ></v-text-field>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </div>-->
    <!--  </div>-->

    <!--  <div class="input-code">-->
    <!--    &lt;!&ndash;    <input type="password" v-model="pwd"/>-->
    <!--        <span v-if="error.pwd" class="err-msg" style="background-color: white">{{ error.pwd }}</span>&ndash;&gt;-->
    <!--    <v-row>-->
    <!--      <v-col>-->
    <!--        <span style="color: white;margin-left: 46%;letter-spacing: 12px">密码</span>-->
    <!--      </v-col>-->
    <!--      <v-col>-->
    <!--        <v-text-field style="margin-left: -0%;width: 90%"-->
    <!--                      type="password"-->
    <!--                      v-model="pwd"-->
    <!--                      dark-->
    <!--                      outlined-->
    <!--        ></v-text-field>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--  </div>-->
    <!--  <div class="input-wrap">-->
    <!--    <v-btn style="height: 50px;width: 260%;margin-left: -80%;background-color: rgba(0,0,0,0);color: white"-->
    <!--           @click="login">登录-->
    <!--    </v-btn>-->
    <!--  </div>-->
    <!--</div>-->
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      imgSrc: require('../assets/png/loading.png'),
      name: 'admin',
      pwd: '123',
      showPwd: false,
      error: {
        name: '',
        pwd: '',
      }
    }
  },
  mounted() {
    // console.log('net:', process.env)
  },
  methods: {
    check(name, pwd) {
      if (!name) {
        this.error.name = '登录名'
        return false
      }
      if (!pwd) {
        this.error.pwd = '密码'
        return false
      }
      return true
    },
    login() {
      const {name, pwd,} = this
      if (!this.check(name, pwd)) return
      if (name === 'admin' && pwd === '123') {
        this.$store.commit('LOGIN', true)
        // this.$router.push({name: 'index'})
        this.$router.push({name: 'index'})
      } else {
        alert('用户名错误')
      }
    }
  },
}
</script>

<style scoped>
.background-bg{
  background-image: url("~@/assets/png/loading.png");
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  position: relative;
}
.bg {
  width: 18%;
  margin: auto;
  z-index: 1;
  position: relative;
}

.input {
  z-index: 1;
  position: absolute;
}

.input-code {
  margin-top: 9%;
  margin-left: 9%;
  z-index: 1;
  position: absolute;
}

.input-wrap {
  width: 35%;
  margin-top: 30%;
  margin-left: 36%;
  z-index: 1;
  position: absolute;
}

.background {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
}

.dl {
  width: 67%;
  margin-top: -74%;
  margin-left: 9%;
  z-index: 2;
  position: absolute;
}
</style>